import React, { useEffect } from "react";

import Main from '../components/Accounts/Main';
import AccountTypes from '../components/Home/AccountTypes';
import HowСhoose from '../components/Accounts/HowСhoose';
import TryExceptional from '../components/Home/TryExceptional';

function Accounts() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='bg-[#000]'>
        <Main/>
        <AccountTypes/>
        <HowСhoose/>
        <TryExceptional/>
    </div>
  );
}

export default Accounts;