import React from 'react';

import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import main from '../../img/AboutUs/main.png'

import m1 from '../../img/AboutUs/main1.png'
import m2 from '../../img/AboutUs/main2.png'
import m3 from '../../img/AboutUs/main3.png'

import Fade from 'react-reveal/Fade'

function Main() {

  const { t } = useTranslation();

  return (
    <div className='relative overflow-hidden xl:h-[853px]'>
        <img src={main} alt="" className='absolute w-full h-full object-cover'/>
        <Fade left><img src={m1} className='absolute left-0 top-[500px] hidden xl:block' alt="" /></Fade>
        <Fade bottom><img src={m2} className='absolute left-1/4 top-[100px] xl:top-[300px]' alt="" /></Fade>
        <Fade right><img src={m3} className='absolute right-0 top-[400px] hidden xl:block' alt="" /></Fade>
        <div className='max-w-[1280px] mx-[20px] xl:mx-auto xl:mt-[180px] mt-[100px] flex flex-col items-center z-50 relative'>
            <p className='oswald text-[20px] xl:text-[55px] uppercase font-[700] text-[#98CBFF] text-center'>{t('about.1')}</p>
            <p className='max-w-[662px] open-sans font-[300] xl:text-[17px] text-[#FFF] mt-[20px] text-center'>{t('about.2')}</p>
            <Link>
              <div className='button-main mt-[35px] cursor-pointer mx-auto'>
                  <p className='text-[#F1EDEE] text-[17px] furore'>{t('main.2')}</p>
              </div>
            </Link>
        </div>
    </div>

  );
}

export default Main;