import React, { useEffect } from "react";

import Main from '../components/TradingAssets/Main';
import Advanced from '../components/TradingAssets/Advanced';
import Diverse from '../components/TradingAssets/Diverse';
import Exceptional from '../components/TradingAssets/Exceptional';
import TradingFees from '../components/TradingAssets/TradingFees';
import TryExceptional from '../components/Home/TryExceptional';

function TradingAssets() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='bg-[#000]'>
        <Main/>
        <Advanced/>
        <Diverse/>
        <Exceptional/>
        <TradingFees/>
        <TryExceptional/>
    </div>
  );
}

export default TradingAssets;