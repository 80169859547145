import React from 'react';

import { Link } from 'react-router-dom';

import { useTranslation, Trans } from "react-i18next";

import e1 from '../../img/Home/e1.png'
import spaceship from '../../img/Home/spaceship.png'
import img from '../../img/Home/img.png'
import satellite from '../../img/Home/satellite.png'
import component from '../../img/Home/component.png'

import Earth from './Earth';

import Fade from 'react-reveal/Fade';
import Rotate from 'react-reveal/Rotate';

function Main() {

  const { t } = useTranslation();

  return (
    <>
      <div className='bg-main'>
        <div className='flex flex-col items-center relative overflow-hidden mx-auto'>
          <Rotate duration={5000}>
            <img src={e1} alt="" className='absolute top-[300px] xl:top-0'/>
          </Rotate>
          <div className='mt-[140px] xl:mt-[175px] flex flex-col items-center xl:w-[600px] relative  z-[501]'>
            <Fade top>
              <p className='text-[#98CBFF] oswald text-[40px] xl:text-[40px] font-[700] uppercase text-center'>FIPS Finance & Development</p>
              <p className='text-[#FFFFFF] oswald text-[30px] xl:text-[55px] font-[700] uppercase'>{t('main.1')}</p>      
              <Link to="https://user.fips-finance.net/">
                <div className='button-main mt-[35px] cursor-pointer'>
                  <p className='text-[#F1EDEE] text-[17px] furore'>{t('main.2')}</p>
                </div>
              </Link>
            </Fade>  
            <Fade left duration={1500}>
              <img src={spaceship} alt="" className='hidden xl:block absolute top-[250px] z-50 left-[50px]'/>
            </Fade>
          </div>
          <Earth/>
          <Fade duration={2000}>
            <img src={img} alt="" className='absolute top-[360px] xl:top-[220px]'/>
          </Fade>
          <Fade left duration={3000}>
            <img src={satellite} alt="" className='absolute top-[600px] mr-[800px]'/>
          </Fade>
        </div>
      </div>
      <div className='shadow-main w-full h-[710px] mt-[-700px]  sm:mt-[-400px] z-[500] relative'>
        <img src={component} className='hidden xl:block absolute left-1/2 -translate-x-1/2 top-[350px]' alt="" />
        <div className='h-[390px]'></div>
        <div className='block-main h-[233px] max-w-[1280px] flex items-center justify-center flex-col mx-[20px] xl:mx-auto'>
          <p className='oswald uppercase font-[700] xl:text-[45px] text-[#98CBFF]'>{t('main.3')}</p>
          <p className='open-sans font-[300] xl:text-[17px] text-[#FFF] mt-[20px] text-center mx-[10px] xl:mx-0'><Trans>{t('main.4')}</Trans></p>
        </div>
      </div>
    </>

  );
}

export default Main;