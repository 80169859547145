import React from 'react';

import { Link } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import main from '../../img/Accounts/main.png'
import blick from '../../img/Accounts/blick.png'

import Fade from 'react-reveal/Fade'

function Main() {

  const { t } = useTranslation();

  return (
    <div className='relative overflow-hidden xl:h-[1000px]'>
        <div className='max-w-[1280px] mx-[20px] xl:mx-auto xl:mt-[180px] mt-[100px] flex flex-col items-center'>
            <p className='oswald text-[20px] xl:text-[55px] uppercase font-[700] text-[#98CBFF] text-center'>{t('accounts.1')}</p>
            <p className='max-w-[706px] open-sans font-[300] xl:text-[17px] text-[#FFF] mt-[20px] text-center'>{t('accounts.2')}</p>
            <Link>
              <div className='button-main mt-[35px] cursor-pointer mx-auto'>
                  <p className='text-[#F1EDEE] text-[17px] furore'>{t('main.2')}</p>
              </div>
            </Link>
        </div>
        <Fade bottom><img src={main} className='right-0 top-[300px] xl:absolute mt-[30px] xl:mt-0' alt="" /></Fade>
        <Fade left duration={2000}><img src={blick} className='left-0 absolute top-0 hidden xl:block' alt="" /></Fade>
    </div>

  );
}

export default Main;