// src/ThreeDarkEarth.js
import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

// Import your Earth texture image
import earthTexture from '../../img/Home/earth.jpg'; // Adjust the path based on your file location

const ThreeDarkEarth = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        let scene, camera, renderer;

        // Scene
        scene = new THREE.Scene();
        
        // Camera
        camera = new THREE.PerspectiveCamera(100, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.z = 2;

        // Renderer
        renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }); // Enable alpha for transparency
        renderer.setSize(window.innerWidth, window.innerHeight);
        mountRef.current.appendChild(renderer.domElement);

        // Earth texture
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load(earthTexture); // Use imported image

        // Make the material transparent
        const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });

        // Adjusting color and brightness
        const darkColor = new THREE.Color(0.3, 0.3, 0.5); // Darker color
        material.color = darkColor;

        // Sphere geometry for the Earth
        const geometry = new THREE.SphereGeometry(1, 32, 32);
        const earth = new THREE.Mesh(geometry, material);
        scene.add(earth);

        // Animation Loop
        const animate = () => {
            requestAnimationFrame(animate);

            // Rotate the Earth
            earth.rotation.y += 0.001;

            renderer.render(scene, camera);
        };

        animate();

        // Resize handling
        const handleResize = () => {
            const { innerWidth, innerHeight } = window;
            camera.aspect = innerWidth / innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(innerWidth, innerHeight);
        };

        window.addEventListener('resize', handleResize);

        // Clean up
        
        return () => {
            window.removeEventListener('resize', handleResize);
            if (mountRef.current && renderer.domElement) {
                mountRef.current.removeChild(renderer.domElement);
            }
            renderer.dispose();
            texture.dispose();
        };
    }, []);
    

    return <div ref={mountRef} className="mt-[-290px] sm:mt-[-200px] scale-[0.48] sm:scale-75 xl:scale-100"/>;
};

export default ThreeDarkEarth;
