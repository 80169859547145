import React from 'react';

import { useTranslation, Trans } from "react-i18next";

import imgman from '../../img/TradingAssets/money.png'

import Fade from 'react-reveal/Fade'

function Diverse() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#000] mt-[80px] xl:mt-[160px] relative overflow-hidden'>
       <div className='flex flex-col xl:flex-row-reverse justify-between max-w-[1280px] mx-[20px] xl:mx-auto'>
            <div>
                <p className='oswald text-[20px] xl:text-[45px] font-[700] text-[#98CBFF] uppercase'><Trans>{t('tradingassets.6')}</Trans></p>
                <p className='xl:max-w-[525px] mt-[15px] xl:mt-[30px] text-white open-sans text-[14px] xl:text-[17px] font-[300]'>{t('tradingassets.7')}</p>
                <div className='button-main mt-[35px] cursor-pointer'>
                  <p className='text-[#F1EDEE] text-[17px] furore'>{t('main.2')}</p>
                </div>
            </div>
            <div>
                <Fade right>
                    <img src={imgman} alt="" className='max-w-[446px]'/>
                </Fade>
            </div>
       </div>
    </div>

  );
}

export default Diverse;